var items = [];
const userData = JSON.parse(localStorage.getItem("userData"));
let privacyChildren = [
    {
        title: "Privacy Policy",
        route: "privacyPolicy",
    },
    {
        title: "Terms",
        route: "terms",
    },
    {
        title: "Guidelines",
        route: "guidelines",
    },
    {
        title: "FAQs",
        route: "FAQs",
    },
];
if (userData !== null && userData.role === "admin") {
    items.push(
        {
            title: "Admins",
            icon: "UserIcon",
            route: "home",
            route: "admin-management",
        },
        {
            title: 'Users',
            icon: 'UsersIcon',
            route: 'home',
            route: 'all-users',
        },
        {
            title: 'Chats',
            icon: 'MessageCircleIcon',
            route: 'home',
            route: 'chats',
        },
        {
            title: "Events",
            icon: "CalendarIcon",
            route: "home",
            route: "event",
        },
        {
            title: "Topics",
            icon: "BoxIcon",
            route: "home",
            route: "topics",
        },
        {
            title: 'Steps',
            icon: 'Edit3Icon',
            route: 'home',
            route: 'steps',
        },
        {
            title: "Policies",
            icon: "PocketIcon",
            route: "home",
            route: "privacyPolicy",
            children: privacyChildren,
        },
        {
            title: "Notification",
            icon: "BellIcon",
            route: "home",
            route: "create-notification",
        },
    )
} else if (userData !== null && userData.role === "eventManager") {
    items.push(
        {
            title: "External events",
            icon: "CalendarIcon",
            route: "home",
            route: "external-event",
        },
    )
}
export default items;